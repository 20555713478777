import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import ChinoisImg from '../assets/images/chinois-hsk/college-lycee-lafayette-chinois-hsk.png'
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import '../../translation';
import { useTranslation } from 'react-i18next';

const DeleEspagnol = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const t = useTranslation('chinoisHsk').t;

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Chinois HSK | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth text'>
                    <div className='content-image text-center'>
                        <img width='300px' src={ChinoisImg} alt='chinois-hsk' />
                    </div>
                    <div className='content-title mb-0'>{t('text1')}</div>
                    <p dangerouslySetInnerHTML={{__html: t('text2')}}></p><br/>
                    <div className='content-title mb-0'>{t('text3')}</div>
                    <p dangerouslySetInnerHTML={{__html: t('text4')}}></p><br/>
                    <p dangerouslySetInnerHTML={{__html: t('text5')}}></p><br/>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default DeleEspagnol;